import React from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setLocales } from "../../actions";

const GuestHeader = ({ location, lang, onLangSwitch }) => {
    const { pathname } = location;
    const { locales } = lang;
    const { navigations } = lang[locales];
    let logo = "logo-mgfriends-4.png";
    let menuNav = "menu-nav-4";
    switch (pathname.split("/")[1]) {
        case "":
        case undefined:
        case null:
        case "about-us":
        case "about":
        case "landingpage":
        case "page":
        case "pages":
        case "join":
            logo = "logo-mgfriends-1.png";
            menuNav = "menu-nav";
            break;
        case "privacy-policy":
        case "points-exchange-procedure":
        case "how-to-join":
            logo = "logo-mgfriends-3.png";
            menuNav = "menu-nav-3";
            break;
        case "mgtabletop":
            logo = "logo-mgfriends-1.png";
            menuNav = "menu-nav-4";
            break;
        default:
            break;
    }
    return (
        <section className={`${menuNav}`}>
            <nav className="menu-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-2">
                            <div>
                                <Link to="/">
                                    <img
                                        src={`${
                                            process.env.PUBLIC_URL
                                        }/assets/images/logo/${logo}`}
                                        width="135"
                                        height="60"
                                        alt=""
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="col-xs-10">
                            <ul className="list-menu">
                                <li
                                    className={
                                        pathname && pathname === "/"
                                            ? `active`
                                            : undefined
                                    }
                                >
                                    <NavLink to="/">{navigations.home}</NavLink>
                                </li>
                                <li
                                    className={
                                        pathname && pathname === "/privacy-policy"
                                            ? `active`
                                            : undefined
                                    }
                                >
                                    <NavLink to="/privacy-policy">{navigations.privacyPolicy}</NavLink>
                                </li>
                                <li
                                    className={
                                        pathname && pathname === "/about"
                                            ? `active`
                                            : undefined
                                    }
                                >
                                    <NavLink to="/about">
                                        {navigations.about}
                                    </NavLink>
                                </li>
                                <li
                                    className={
                                        pathname && pathname === "/join"
                                            ? `active`
                                            : undefined
                                    }
                                >
                                    <NavLink to="/join">
                                        {navigations.join}
                                    </NavLink>
                                </li>
                                <li
                                    className={
                                        pathname && pathname === "/contact"
                                            ? `active`
                                            : undefined
                                    }
                                >
                                    <NavLink to="/contact">
                                        {navigations.contact}
                                    </NavLink>
                                </li>
                                <li className="divider-menu">|</li>
                                {/* <li className="li-button-signup">
                                    <button
                                        type="button"
                                        data-toggle="modal"
                                        data-target=".signupModal"
                                    >
                                        {navigations.signup}
                                    </button>
                                </li> */}
                                <li className="dropdown">
                                    <a
                                        href="#/"
                                        className="dropdown-toggle"
                                        data-toggle="dropdown"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        {locales === "en" ? (
                                            <i
                                                className="flag-icon flag-icon-gb"
                                                style={{ marginRight: "5px" }}
                                            />
                                        ) : (
                                            <i
                                                className="flag-icon flag-icon-id"
                                                style={{ marginRight: "5px" }}
                                            />
                                        )}
                                        <span className="caret" />
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a
                                                href="#/"
                                                onClick={onLangSwitch("id")}
                                            >
                                                <i className="flag-icon flag-icon-id" />
                                            </a>
                                        </li>
                                        <li
                                            role="separator"
                                            className="divider"
                                        />
                                        <li>
                                            <a
                                                href="#/"
                                                onClick={onLangSwitch("en")}
                                            >
                                                <i className="flag-icon flag-icon-gb" />
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </section>
    );
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLangSwitch: locales => e => {
            e.preventDefault();
            return dispatch(setLocales(locales));
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(GuestHeader)
);
