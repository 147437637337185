import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { withRouter } from "react-router-dom";
import { sendContact } from "../../actions/contact";
import ReCAPTCHA from "react-google-recaptcha";
import { asyncValidateContact, validateContact } from "../../validate/contactUs";

import MainPage from './MainPage';

const renderField = ({ input, label, type, meta: { touched, error } }) => {
    const classes = ["hubungi-kami"];
    return (
        <div
            className={
                touched && error
                    ? classes.concat(["has-error", "has-feedback"]).join(" ")
                    : classes.join(" ")
            }
        >
            <label className="control-label sr-only">{label}</label>
            <input
                {...input}
                placeholder={label}
                type={type}
                id={label}
                aria-describedby={`${label}Status`}
            />
            {touched && error && (
                <span
                    className="glyphicon glyphicon-remove form-control-feedback"
                    aria-hidden="true"
                    style={{ top: 12 }}
                />
            )}
        </div>
    );
};

const renderTextArea = ({ input, label, type, meta: { touched, error } }) => {
    const classes = ["hubungi-kami"];
    return (
        <div
            className={
                touched && error
                    ? classes.concat(["has-error", "has-feedback"]).join(" ")
                    : classes.join(" ")
            }
        >
            <label className="control-label sr-only">{label}</label>
            <textarea
                className="input-pesan"
                {...input}
                placeholder={label}
                type={type}
                id={label}
                aria-describedby={`${label}Status`}
            />
            {touched && error && (
                <span
                    className="glyphicon glyphicon-remove form-control-feedback"
                    aria-hidden="true"
                    style={{ top: 12 }}
                />
            )}
        </div>
    );
};

const Captcha = ({ input: { onChange }, meta: { touched, error } }) => (
    <div className="recaptca-box">
        <ReCAPTCHA
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY}
            onChange={onChange}
            className="recaptcha"
        />
        {touched && error && (
            <span
                className="glyphicon glyphicon-remove form-control-feedback"
                aria-hidden="true"
                style={{ top: 12, right: 70 }}
            />
        )}
    </div>
);

class ContactPage extends Component {
    render() {
        return (
            <MainPage>
                <section className="content--1contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <h1>Get in Touch</h1>
                                <div className="form-contact">
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <h3>MG Friends</h3>
                                            <p>
                                                <span>
                                                    <img
                                                        className="map"
                                                        src={`${
                                                            process.env
                                                                .PUBLIC_URL
                                                        }/assets/images/icon/map-b.png`}
                                                        alt=""
                                                    />
                                                </span>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.props.lang[
                                                            this.props.lang
                                                                .locales
                                                        ].contents.contact
                                                            .address
                                                    }}
                                                />
                                            </p>
                                            <p className="lh30px">
                                                <span>
                                                    <img
                                                        src={`${
                                                            process.env
                                                                .PUBLIC_URL
                                                        }/assets/images/icon/phone-b.png`}
                                                        alt=""
                                                    />
                                                </span>
                                                (62 21) 2922 3933 ext 1123
                                            </p>
                                            <p className="lh30px">
                                                <span>
                                                    <img
                                                        src={`${
                                                            process.env
                                                                .PUBLIC_URL
                                                        }/assets/images/icon/mail-b.png`}
                                                        alt=""
                                                    />
                                                </span>
                                                enquiries@mgfriends.com
                                            </p>
                                        </div>
                                        <div className="col-xs-6">
                                            <form
                                                onSubmit={
                                                    this.props.handleSubmit
                                                }
                                            >
                                                <h3>
                                                    {
                                                        this.props.lang[
                                                            this.props.lang
                                                                .locales
                                                        ].contents.contact
                                                            .subheading
                                                    }
                                                </h3>
                                                <div className="row hubungi-kami">
                                                    <div className="col-xs-6">
                                                        <Field
                                                            component={
                                                                renderField
                                                            }
                                                            type="text"
                                                            name="Name"
                                                            label={
                                                                this.props.lang[
                                                                    this.props
                                                                        .lang
                                                                        .locales
                                                                ].forms.contact
                                                                    .name
                                                            }
                                                        />
                                                        <Field
                                                            component={
                                                                renderField
                                                            }
                                                            type="text"
                                                            name="Phone"
                                                            label={
                                                                this.props.lang[
                                                                    this.props
                                                                        .lang
                                                                        .locales
                                                                ].forms.contact
                                                                    .phone
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-xs-6">
                                                        <Field
                                                            component={
                                                                renderField
                                                            }
                                                            type="text"
                                                            name="Email"
                                                            label={
                                                                this.props.lang[
                                                                    this.props
                                                                        .lang
                                                                        .locales
                                                                ].forms.contact
                                                                    .email
                                                            }
                                                        />
                                                        <Field
                                                            component={
                                                                renderField
                                                            }
                                                            type="text"
                                                            name="Company"
                                                            label={
                                                                this.props.lang[
                                                                    this.props
                                                                        .lang
                                                                        .locales
                                                                ].forms.contact
                                                                    .office
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-xs-12">
                                                        <Field
                                                            component={
                                                                renderTextArea
                                                            }
                                                            name="Message"
                                                            placeholder="Pesan"
                                                            label={
                                                                this.props.lang[
                                                                    this.props
                                                                        .lang
                                                                        .locales
                                                                ].forms.contact
                                                                    .subject
                                                            }
                                                        />
                                                        <div className="recaptcha-layer">
                                                            <Field
                                                                component={
                                                                    Captcha
                                                                }
                                                                name="recaptcha"
                                                            />
                                                            <div className="for-send">
                                                                <div className="tooltip-recaptcha">
                                                                    <a
                                                                        href="#/"
                                                                        className="tooltip-link"
                                                                    >
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-link"
                                                                            disabled={
                                                                                this
                                                                                    .props
                                                                                    .pristine ||
                                                                                this
                                                                                    .props
                                                                                    .submitting
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={`${
                                                                                    process
                                                                                        .env
                                                                                        .PUBLIC_URL
                                                                                }/assets/images/icon/send-pesan.png`}
                                                                                alt=""
                                                                            />
                                                                        </button>
                                                                        <span>
                                                                            {
                                                                                this
                                                                                    .props
                                                                                    .lang[
                                                                                    this
                                                                                        .props
                                                                                        .lang
                                                                                        .locales
                                                                                ]
                                                                                    .forms
                                                                                    .contact
                                                                                    .captcha
                                                                            }
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content--2contact" />
            </MainPage>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.lang
    };
};

const mapDispatchToProps = (dispatch, { history, reset }) => {
    return {
        onSubmit: values =>
            dispatch(
                sendContact({
                    values,
                    history,
                    reset
                })
            )
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "contact",
            validate: validateContact,
            asyncValidate: asyncValidateContact,
            asyncBlurFields: [
                "Name",
                "Phone",
                "Email",
                "Company",
                "Message",
                "recaptcha"
            ]
        })(ContactPage)
    )
);
